import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import css from "@css/components/blocks/common/PageHeader.module.styl"

// const ThreeJs = loadable( () => import('@threeSectionHeader/Main.js'))

export default function PageHeader({l1, l2, l3})
{
    const { bgImg } = useStaticQuery(graphql`
        query PageHeaderQuery {
            bgImg : file(relativePath: { eq: "Page__img__header-bg@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return(
        <div className={css.container}>
            <div className="page-header-comachi"/>
            {/*<BackgroundImage className={css.bg} fluid={bgImg.childImageSharp.fluid}/>*/}
            <div className={css.inner}>
                <div className={css.block}>
                    <h1 className={css.title}>
                        <span className={css.l1} dangerouslySetInnerHTML={{__html:l1}}/>
                        <span className={css.l2}>{l2}</span>
                    </h1>
                    { l3 &&
                        <p className={css.subTitle}>
                                {l3}
                        </p>
                    }
                </div>
                <div id='header-model' className={css.model}></div>
            </div>
        </div>
    )
}
