import React from "react"
import { graphql} from "gatsby"
import Iframe from 'react-iframe'

import { useTranslation } from 'react-i18next'

import { FluidImage } from "@components/Image"
import Link from "@components/Link"
import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
import { useURL } from '@hooks/useURL'
import PageHeader from "@components/blocks/common/PageHeader"
import AndMoreSvg from "@images/Anniversary__img__and-more.svg"
import LinkSvg from "@images/Common__icn__link--green.svg"
import LinkWSvg from "@images/Common__icn__link.svg"
import TwitterSVG from '@images/GlobalFooterSNS__icn__twitter.svg'

import css from "@css/components/blocks/anniversary/Content.module.styl"
import { map } from "lodash"

export default function Comic({data})
{
    const {t} = useTranslation()
	// const { kv } = data
    const urls = useURL()
    
    const musicList = [
        {
            title: `MIRAI`,
            text: `作詞・作曲 / 佐藤貴文（BNSI）`,
            url: `https://nex-tone.link/A00087647`,
            img: data.music01,
        }, {
            title: `future beat`,
            text: `作詞・作曲 / ミフメイ（BNSI）`,
            url: `https://nex-tone.link/A00087648`,
            img: data.music02,
        }, {
            title: `Future Melody -心から未来へ-`,
            text: `作曲 / Takahiro Eguchi (SuperSweep)<br/>作詞 / Fumihisa Tanaka (SuperSweep)`,
            url: `https://nex-tone.link/A00087649`,
            img: data.music03,
        }, {
            title: `Future Wonderland`,
            text: `作詞・作曲 / kors k(EDP)`,
            url: `https://nex-tone.link/A00088290`,
            img: data.music04,
        }, {
            title: `Mirai Rolling Bass`,
            text: `作詞・作曲 / AJURIKA`,
            url: `https://nex-tone.link/A00088291`,
            img: data.music05,
        }, {
            title: `同じかもね`,
            text: `作詞・作曲 / 岡田 祥（BNSI）`,
            url: 'https://nex-tone.link/A00088892',
            img: data.music06,
        }, {
            title: `オーバーライド`,
            text: `作詞・作曲 / picco`,
            url: 'https://nex-tone.link/A00088891',
            img: data.music07,
        },
        // {
        //     title: ``,
        //     text: ``,
        //     url: false,
        //     img: data.musicComingsoon,
        //     commingsoon: true,
        // },
    ]

	return (
		<React.Fragment>
			<SEO title={'3rd Anniversary'} />
			<main className={css.container} id="main">
                <PageHeader l1="3rd ANNIV" l2={t('anniversary__headerTitle2')}/>
                <figure className={css.kv}>
                    <FluidImage data={data.kv}/>
                </figure>
				<div className={css.content}>
                    <section className={css.section}>
                        <div className={css.sectionInner}>
                            <h2 className={css.title}>新曲発表＆楽曲配信開始！</h2>
                            <div className={css.text}>
                                <p>
                                    ボーカロイド™デビュー3周年を記念して、新曲4曲をリリースします！<br/>
                                    楽曲配信も始まりますので、ぜひ聞いてみてくださいね！
                                </p>
                                <p>
                                    ※配信スケジュールは<Link to={urls.twitter}>公式SNS</Link>で順次お知らせいたします。
                                </p>
                            </div>
                            <div className={css.musicListWrapper}>
                                <div className={css.musicList}>
                                    {musicList.map(({title,text,url,img, commingsoon, label},i)=>{
                                        if( url ){
                                            return(
                                                <Link to={url} target="_blank" className={css.item} key={i}>
                                                    <span className={css.now}>配信中</span>
                                                    <figure className={css.thumbnail}>
                                                        <FluidImage data={img} />
                                                    </figure>
                                                    <h4 className={css.musicName}>
                                                        {title}
                                                        <span className={css.icon}><LinkSvg/></span>
                                                    </h4>
                                                    <p className={css.musicText} dangerouslySetInnerHTML={{ __html: text }} />
                                                </Link>
                                            )
                                        } else {
                                            return (
                                                <div className={css.item} key={i}>
                                                    { !commingsoon ? <span className={css.commingsoon}>{ label ? label : "まもなく配信"}</span> : null}
                                                    <figure className={css.thumbnail}>
                                                        <FluidImage data={img} />
                                                    </figure>
                                                    <h4 className={css.musicName}>{title}</h4>
                                                    <p className={css.musicText} dangerouslySetInnerHTML={{ __html: text }} />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={css.section}>
                        <div className={css.movieBg}>
                            <div className={css.movieBg__img} style={{backgroundImage: `url(${data.bg.publicURL})`}}></div>
                        </div>
                        <div className={css.sectionInner}>
                            <h2 className={css.titleWhite}>BanaDIVE™AXによる特別DJ映像配信！</h2>
                            <div className={`${css.textWhite} ${css.width60}`}>
                                <p>
                                    バンダイナムコ研究所のインタラクティブバーチャルパフォーマンスシステムBanaDIVE™AXによるミライ小町スペシャルDJ映像をご覧ください！
                                </p>
                            </div>
                            <div className={css.movieWrapper}>
                                <div className={css.movie}>
                                    <Iframe url={`https://www.youtube.com/embed/PwPfo0s5IgE`} width="640px" height="400px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                </div>
                            </div>
                            <figure className={css.movieChara}>
                                <FluidImage data={data.chara} />
                            </figure>
                        </div>
                    </section>
                    <section className={`${css.collabo} ${css.section}`}>
                        <div className={css.collabo__bg}>
                            <div className={css.collabo__bg__img} style={{ backgroundImage: `url(${data.collaboBg.publicURL})` }}></div>
                        </div>
                        <div className={css.collabo__inner}>
                            <figure className={css.collabo__main}>
                                <FluidImage data={data.collaboMain} />
                            </figure>
                            <div className={css.collabo__detail}>
                                <h2 className={css.titleWhite}>クリエイター・アーティストコラボ!</h2>
                                <div className={`${css.textWhite}`}>
                                    <p>
                                        室田雄平先生に、素敵なミライ小町を描いていただきました！<br/>
                                        新曲ジャケットの他、グッズ制作も予定していますよ！
                                    </p>
                                    <Link className={css.button} to='https://twitter.com/muromuromurota' target="_blank">
                                        <span className={css.icon}><TwitterSVG/></span>室田雄平先生(@muromuromurota)
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`${css.process} ${css.section}`}>
                        <div className={css.process__bg}>
                            <div className={css.collabo__bg__img} style={{ backgroundImage: `url(${data.processBg.publicURL})` }}></div>
                        </div>
                        <div className={css.process__inner}>
                            <div className={css.process__detail}>
                                <h2 className={css.titleWhite}>
                                    新曲『Mirai Rolling Bass』の制作過程をご紹介！
                                </h2>
                                <div className={`${css.textWhite}`}>
                                    <p>
                                        クリエイターによる楽曲解説動画です！
                                    </p>
                                    <div className={css.process__profile}>
                                        <figure className={css.process__profile__img}>
                                            <FluidImage data={data.profile} />
                                        </figure>
                                        <div className={css.process__profile__detail}>
                                            <h5>AJURIKA</h5>
                                            <p>作詞、作曲家、DJ</p>
                                        </div>
                                    </div>
                                    <p>
                                        『アイドルマスターシンデレラガールズ』『鉄拳』『太鼓の達人』などの音楽を担当し、声優への楽曲提供もしている。<br />
                                        現在はフリーで音楽制作をしているが元々はバンダイナムコスタジオサウンドチームスタッフ。
                                    </p>
                                    <div className={css.buttons}>
                                        <Link className={css.button} to='https://twitter.com/AJURIKA' target="_blank">
                                            <span className={css.icon}><TwitterSVG /></span>AJURIKA(@AJURIKA)
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <figure className={css.process__main}>
                                <div className={css.movie}>
                                    <Iframe url={`https://www.youtube.com/embed/K9XcF00EGao`} width="640px" height="400px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className={css.section}>
                        <div className={`${css.sectionInner}`}>
                            <div className={css.banners}>
                                <div className={css.banners__item}>
                                    <h2 className={css.banners__item__title}>
                                        <img src={data.bannerTitle05.publicURL} alt="ミライ小町SHOPオープン!" />
                                    </h2>
                                    <div className={css.banners__item__text}>
                                        <span>マンガハックさんとの</span>
                                        <span>コラボレーショショップが</span>
                                        <span>オープンしましたよ！</span>
                                        <br/>
                                        <span>オリジナル商品企画を</span>
                                        <span>お楽しみに！</span>
                                    </div>
                                    <Link className={css.banners__item__img} to="https://mangahack.myshopify.com/?sc_cid=txt_tw_20211021" target="_blank">
                                        <FluidImage data={data.banner05} alt="ミライ小町SHOPならではのオリジナルグッズを発売中!!" />
                                        <div className={css.banners__item__button}>
                                            ミライ小町SHOPはこちら！
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link>
                                </div>
                                <div className={css.banners__item}>
                                    <h2 className={css.banners__item__title}>
                                        <img src={data.bannerTitle04.publicURL} alt="モデルデータアップデート&ボイスデータ配布開始！" />
                                    </h2>
                                    <div className={css.banners__item__text}>
                                        <span>水着モデルや</span>
                                        <span>新規モーション</span>
                                        <span>データ追加の他、</span>
                                        <span>Blenderプロジェクト、</span>
                                        <span>ボイスデータの</span>
                                        <span>配布も</span>
                                        <span>開始しました！</span><br/>
                                        <span>皆様の創作活動に</span>
                                        <span>ご利用ください！</span>
                                    </div>
                                    <Link className={css.banners__item__img} to="/download/">
                                        <FluidImage data={data.banner04_2} alt="" />
                                        <div className={css.banners__item__button}>
                                            ダウンロードはこちら
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link>
                                </div>
                                <div className={css.banners__item}>
                                    <h2 className={css.banners__item__title}>
                                        <img src={data.bannerTitle03.publicURL} alt="ミライ小町のLINEスタンプができました！" />
                                    </h2>
                                    <div className={css.banners__item__text}>
                                        様々なシーンで使いやすい、ちび小町ちゃんのスタンプです！<br />ぜひ使ってみてくださいね！
                                    </div>
                                    <Link className={css.banners__item__img} to="https://line.me/S/sticker/15418986" target="_blank">
                                        <FluidImage data={data.banner03} alt="ミライ小町 LINEスタンプ発売！全24種類/¥120" />
                                        <div className={css.banners__item__button}>
                                            ダウンロードはこちら
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link>
                                    <div className={css.banners__item__text}>
                                        ミライ小町スタンプ第２弾も販売開始！<br/>ぜひ使ってみてくださいね！
                                    </div>
                                    <Link className={css.banners__item__img} to="https://store.line.me/stickershop/product/16086092" target="_blank">
                                        <FluidImage data={data.banner03_2} alt="ミライ小町 様々なシーンで使いやすい！ LINEスタンプ第2弾発売！全32種" />
                                        <div className={css.banners__item__button}>
                                            ダウンロードはこちら
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link>
                                </div>
                                <div className={css.banners__item}>
                                    <h2 className={css.banners__item__title}>
                                        <img src={data.bannerTitle02.publicURL} alt="アソビストア期間限定店長就任！" />
                                    </h2>
                                    <div className={css.banners__item__text}>
                                        アソビストアプレミアム会員も私と一緒の3周年！ということで、期間限定店長に就任しました！<br />
                                        最新テクノロジーで様々な情報をおとどけしますよ！
                                    </div>
                                    <Link className={css.banners__item__img} to="https://asobistore.jp/content/premium_3rd/" target="_blank">
                                        <FluidImage data={data.banner02} alt="アソビストアプレミアム会員3周年記念キャンペーン！"/>
                                        <div className={css.banners__item__button}>
                                            特設サイトはこちら
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link>
                                </div>
                                <div className={css.banners__item}>
                                    <h2 className={css.banners__item__title}>
                                        <img src={data.bannerTitle01.publicURL} alt="3周年記念RTキャンペーン開始！"/>
                                    </h2>
                                    <div className={css.banners__item__text}>
                                        応援してくれる皆様への感謝をこめて、ミライ小町グッズ大放出！<br />応募してくださいね！
                                    </div>
                                    <div className={css.banners__item__img}>
                                        <FluidImage data={data.banner01} alt="ミライ小町デビュー3周年記念 RTキャンペーン！" />
                                        <div className={css.banners__item__buttonDisable}>
                                            多数のご応募ありがとうございました
                                        </div>
                                    </div>
                                    {/* <Link className={css.banners__item__img} to="https://twitter.com/miraikomachi_PR/status/1397024631983280129" target="_blank">
                                        <FluidImage data={data.banner01} alt="ミライ小町デビュー3周年記念 RTキャンペーン！"/>
                                        <div className={css.banners__item__button}>
                                            ご応募はこちら
                                            <span className={css.icon}><LinkWSvg /></span>
                                        </div>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className={css.section}>
                        <div className={`${css.sectionInner2}`}>
                            <h2 className={css.andMore}>
                                <AndMoreSvg/>
                            </h2>
                            <div className={css.textCenter}>
                                <p>
                                    他にも企画が進行中！続報にご期待ください！
                                </p>
                            </div>
                            <div className={css.andmoreBanners}>
                                <div className={css.andmoreBanners__item}>
                                    <div>
                                        <p>Coming Soon</p>
                                    </div>
                                    <figure>
                                        <FluidImage data={data.commingSoon} />
                                    </figure>
                                </div>
                            </div>
                            <div className={css.buttons}>
                                <Link className={css.snsButton} target="_blank" to={urls.twitter}>更新情報は公式SNSで順次お知らせいたします</Link>
                            </div>
                        </div>
                    </section> */}
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        kv : file(relativePath: { eq: "Anniversary__img__kv@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        music01 : file(relativePath: { eq: "Anniversary__img__music-01@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music02 : file(relativePath: { eq: "Anniversary__img__music-02@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music03 : file(relativePath: { eq: "Anniversary__img__music-03@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music04 : file(relativePath: { eq: "Anniversary__img__music-04@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music05 : file(relativePath: { eq: "Anniversary__img__music-05@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music06 : file(relativePath: { eq: "Anniversary__img__music-06@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music07 : file(relativePath: { eq: "Anniversary__img__music-07@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        music08 : file(relativePath: { eq: "Anniversary__img__music-08@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        musicComingsoon : file(relativePath: { eq: "Anniversary__img__music-commingsoon@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        chara : file(relativePath: { eq: "Anniversary__img__chara@2x.png" }) {
            childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
            } }
        }
        bg : file(relativePath: { eq: "Anniversary__img__bg.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
            publicURL
        }
        banner01 : file(relativePath: { eq: "Anniversary__img__banner-01@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner02 : file(relativePath: { eq: "Anniversary__img__banner-02@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner03 : file(relativePath: { eq: "Anniversary__img__banner-03@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner03_2 : file(relativePath: { eq: "Anniversary__img__banner-03-2@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner04 : file(relativePath: { eq: "Anniversary__img__banner-04@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner04_2 : file(relativePath: { eq: "Anniversary__img__banner-04-2@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        banner05 : file(relativePath: { eq: "Anniversary__img__banner-05@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        bannerTitle01 : file(relativePath: { eq: "Anniversary__img__banner-title01.svg" }) {
            publicURL
        }
        bannerTitle02 : file(relativePath: { eq: "Anniversary__img__banner-title02.svg" }) {
            publicURL
        }
        bannerTitle03 : file(relativePath: { eq: "Anniversary__img__banner-title03.svg" }) {
            publicURL
        }
        bannerTitle04 : file(relativePath: { eq: "Anniversary__img__banner-title04.svg" }) {
            publicURL
        }
        bannerTitle05 : file(relativePath: { eq: "Anniversary__img__banner-title05.svg" }) {
            publicURL
        }

        commingSoon : file(relativePath: { eq: "Anniversary__img__comming-soon@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
        }
        collaboMain : file(relativePath: { eq: "Anniversary__img__collabo-main@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
            } }
        }
        collaboBg : file(relativePath: { eq: "Anniversary__img__collabo-bg@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
            publicURL
        }
        profile : file(relativePath: { eq: "Anniversary__img__process-profile@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 310) {
                    ...GatsbyImageSharpFluid
            } }
        }
        processBg : file(relativePath: { eq: "Anniversary__img__process-bg@2x.jpg" }) {
            childImageSharp { fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
            } }
            publicURL
        }
    }
`